import { BuilderComponent } from '@builder.io/react'
import * as c from '@chakra-ui/react'
import React from 'react'
import { useBuilderPage } from 'src/hooks/builder'
import { useHideSearchBar } from 'src/store/context/navbar.context'
import { DisabledResetScroll } from 'src/store/context/scroll.context'

export default function HousiSalesBuilder() {
  useHideSearchBar({})

  const { data, loading, error } = useBuilderPage(
    '/vendas',
    'page-housi-com-vendas'
  )
  if (loading || error) return null
  return (
    <DisabledResetScroll>
      <React.Suspense fallback={<Loading />}>
        <BuilderComponent model="page-housi-com-vendas" content={data} />
      </React.Suspense>
    </DisabledResetScroll>
  )
}

function Loading() {
  return (
    <c.Container px={['s5', 0]}>
      <c.Flex mt="m3" direction="column" gap={['l4', 'l5']}>
        <c.Skeleton h="465px" w="full" borderRadius="20px" />
        <c.Skeleton h="465px" w="full" borderRadius="20px" />
      </c.Flex>
    </c.Container>
  )
}
